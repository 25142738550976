import React from 'react'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'

const Parent = () => {
  return (
    <div>
      <NavBar text="Parent DashBoard" />
      <Footer />
    </div>
  )
}

export default Parent